<template>
  <section class="presentation-assistance-inquire">
    <h2 class="presentation-assistance-inquire__title text-title">Presentation Assistance</h2>
    <p class="presentation-assistance-inquire__description text-description">
      Fill out the form below or contact Tom Grotta at browngrotta arts.
    </p>
    <ValidationObserver
      class="presentation-assistance-inquire__form"
      v-slot="{ invalid }"
      tag="div"
      ref="observer"
    >
      <ValidationProvider
        tag="div"
        class="presentation-assistance-inquire__input"
        :rules="{ required: true, min: 2, max: 255 }"
        v-slot="{ errors }"
      >
        <SubscriptionInput
          class="form-control--inquire"
          placeholder="Your Name"
          v-model="form.name"
          :validateError="errors[0]"
        ></SubscriptionInput>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="presentation-assistance-inquire__input"
        :rules="{ required: true, email: true, min: 2, max: 255 }"
        v-slot="{ errors }"
      >
        <SubscriptionInput
          class="form-control--inquire"
          placeholder="Email Addres"
          v-model="form.email"
          :validateError="errors[0]"
        ></SubscriptionInput>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="presentation-assistance-inquire__input"
        :rules="{ required: true, phone_number: true }"
        v-slot="{ errors }"
      >
        <SubscriptionInput
          class="form-control--inquire"
          placeholder="Phone Number"
          v-model="form.phone"
          :validateError="errors[0]"
        ></SubscriptionInput>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="presentation-assistance-inquire__input"
        :rules="{ required: true, min: 2, max: 255 }"
        v-slot="{ errors }"
      >
        <SubscriptionInput
          class="form-control--inquire"
          placeholder="Business Name"
          v-model="form.business_name"
          :validateError="errors[0]"
        ></SubscriptionInput>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="presentation-assistance-inquire__input"
        :rules="{ required: true, min: 2, max: 255 }"
        v-slot="{ errors }"
      >
        <SubscriptionInput
          class="form-control--inquire"
          placeholder="Business Address"
          v-model="form.business_address"
          :validateError="errors[0]"
        ></SubscriptionInput>
      </ValidationProvider>
      <ValidationProvider tag="div" class="presentation-assistance-inquire__input">
        <DragAndDropFiles
          class="drag-and-drop--lg"
          size="2"
          :file="form.files"
          @input="form.files = $event.files"
        ></DragAndDropFiles>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="presentation-assistance-inquire__input"
        :rules="{ required: true, min: 2, max: 255 }"
        v-slot="{ errors }"
      >
        <BGATextarea
          class="form-control--inquire"
          placeholder="Description"
          v-model="form.description"
          :maxLength="255"
          :validateError="errors[0]"
        ></BGATextarea>
      </ValidationProvider>
      <BaseButton
        v-if="captchaVerifided"
        class="presentation-assistance-inquire__button button--white"
        text="SUBMIT"
        :disabled="invalid || submiting"
        @click="submit"
      ></BaseButton>
      <VueRecaptcha
        v-else
        ref="recaptcha"
        :sitekey="sitekey"
        @verify="captchaVerifided = true"
        @error="captchaVerifided = false"
      />
    </ValidationObserver>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import SubscriptionInput from "@/components/inputs/SubscriptionInput.vue";
import DragAndDropFiles from "@/components/DragAndDropFiles.vue";
import BGATextarea from "@/components/inputs/BGATextarea.vue";
import { VueRecaptcha } from "vue-recaptcha";

import { mapMutations, mapActions } from "vuex";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    IconButton,
    BaseCheckbox,
    SubscriptionInput,
    DragAndDropFiles,
    BGATextarea,
    VueRecaptcha,
  },
  data() {
    return {
      captchaVerifided: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      submiting: false,
      form: {
        name: "",
        email: "",
        phone: "",
        business_name: "",
        business_address: "",
        files: [],
        description: "",
      },
    };
  },
  head: {
    title: function () {
      return {
        inner: "Presentation Assistance browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Presentation Assistance browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Presentation Assistance browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Presentation Assistance browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Presentation Assistance browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Presentation Assistance browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Presentation Assistance browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Presentation Assistance browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Presentation Assistance browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  created() {
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Presentation Assistance",
        to: "/art-advisory/presentation-assistance",
        clickable: true,
      },
      {
        title: "Inquire Presentation Assistance",
        to: "/art-advisory/presentation-assistance/inquire",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicapi/services", ["apiFormsPresentationAssistance"]),
    async submit() {
      this.submiting = true;
      let success = await this.apiFormsPresentationAssistance({
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        business_name: this.form.business_name,
        business_address: this.form.business_address,
        description: this.form.description,
        images: this.form.files,
      });
      if (success) {
        this.form.name = "";
        this.form.email = "";
        this.form.phone = "";
        this.form.business_name = "";
        this.form.business_address = "";
        this.form.description = "";
        this.form.files = [];
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      }
      this.submiting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.presentation-assistance-inquire {
  padding: 23rem 31.5rem 23rem;
  @media screen and (max-width: $xs) {
    padding: 27rem 25px 23rem;
  }
  &__title {
    font-size: 4rem;
    margin-bottom: 10px;
    text-align: center;
  }
  &__description {
    font-weight: 100;
    margin-bottom: 2.5rem;
    text-align: center;
  }
  &__form {
    max-width: 39rem;
    margin: 0 auto;
  }
  &__input {
    margin-bottom: 4.5rem;
  }
  &__button {
    min-width: 100%;
  }
  /*   &__contacts {
    margin-top: 12rem;
  }
  &__sign-up {
    margin: 15rem auto 0;
    max-width: 60rem;
  } */
}
</style>
